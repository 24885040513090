<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("reset") }}</span>
      <span>{{ $t("password") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>{{ $t("reset_instructions") }}</span>
    </template>
    <template v-slot:form>
      <v-form ref="form" lazy-validation @submit.prevent="send">
        <v-text-field
          class="form-input"
          v-model="email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          id="email"
          data-testid="email"
        ></v-text-field>
        <div class="form-buttons">
          <mem-button
            type="submit"
            :btnType="'secondary-dark'"
            :loading="loading"
            :disabled="disableBtn"
            >{{ $t("send") }}</mem-button
          >
          <mem-button
            type="button"
            :btnType="'tertiary-dark'"
            @click="cancel"
            >{{ $t("cancel") }}</mem-button
          >
        </div>
      </v-form>
    </template>
  </auth-layout>
</template>
<script>
import { emailValidator } from "@/common/validators";
import { mapActions } from "vuex";
export default {
  data: () => ({
    email: "",
    rules: {
      email: emailValidator,
    },
    loading: false,
  }),
  computed: {
    disableBtn() {
      return !emailValidator(this.email);
    },
  },
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("../../components/base/BaseButton.vue"),
  },
  methods: {
    ...mapActions(["requestNewPassword"]),
    async send() {
      let redirectUrl = `${window.location.origin}/reset-password`;
      this.loading = true;
      try {
        await this.requestNewPassword({
          email: this.email,
          redirectUrl,
          params: this.$route.query,
        });
      } catch (err) {
        console.log("Reset password error:", err);
      }
      this.$router.push({
        name: "resetEmailSend",
        query: { email: this.email },
      });
    },
    cancel() {
      this.$router.push({ name: "formSignIn", query: this.$route.query });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$route.params?.email) {
        vm.email = vm.$route.params?.email;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.form-buttons {
  margin-top: 40px;
  display: flex;
  @media screen and (max-width: 499px) {
    margin-top: 0;
    flex-direction: column;
  }
  button {
    margin-right: 20px;
    @media screen and (max-width: 499px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
</style>